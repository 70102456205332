import { useState } from "react";
import { Button, Card, message } from "antd";
import { API_CALL, USER_SERVER } from "../utils/network";

function UserAccess() {
  // const [email, setEmail] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [loading, setLoading] = useState(false);

  const generateAccessCode = () => {
    setLoading(true);
    // const payload = { email };
    API_CALL.post(USER_SERVER + "generateAccessCode", {})
      .then((res) => {
        if (res.status === 200) {
          setAccessCode(res.data?.accessCode);
          message.success("New Access Code generated!");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchAccessCode = () => {
    API_CALL.get(USER_SERVER + "getAccessCode")
      .then((res) => {
        if (res.status === 200) {
          setAccessCode(res.data?.accessCode);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Card bordered className="content-card">
        <div className="table-header">
          <h3 style={{ marginBottom: 0 }}>Sandbox Access Management</h3>
        </div>
        <div style={{ padding: "20px 0" }}>
          {/* <h5>Please enter email to grant access</h5> */}
          {/* <Form.Item
              name="email"
              label="User Email"
              rules={[{ required: true, message: "Please input user email!" }]}
            >
              <Input
                placeholder="Enter or paste user email id"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item> */}

          <Button type="primary" htmlType="submit" onClick={generateAccessCode}>
            {loading ? "Generating . . ." : "Generate New Access Code"}
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              marginTop: 30,
            }}
          >
            <h3 style={{ marginBottom: 0 }}>Current Access Code: </h3>
            {!accessCode ? (
              <Button
                type="primary"
                htmlType="submit"
                onClick={fetchAccessCode}
              >
                Show Code
              </Button>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <h1
                  style={{ marginBottom: 0, fontWeight: 600, fontSize: "35px" }}
                >
                  {accessCode}
                </h1>
                <Button
                  type="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(accessCode);
                    message.success("Access Code copied to clipboard!");
                  }}
                >
                  Copy
                </Button>
              </div>
            )}
          </div>
          {/* {accessCode && (
            <div
              style={{
                marginTop: 30,
                display: "flex",
                alignItems: "center",
                gap: 20,
              }}
            >
              <h4>Access Code: {accessCode}</h4>
              <Button
                type="primary"
                onClick={() => navigator.clipboard.writeText(accessCode)}
              >
                Copy
              </Button>
            </div>
          )} */}
        </div>
      </Card>
    </>
  );
}

export default UserAccess;
