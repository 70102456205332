import React from "react";
import { Avatar, Card, message, Switch } from "antd";
import { AiOutlineUser } from "react-icons/ai";

import TableView from "../components/TableView";
import { API_CALL, useGetListApi, USER_SERVER } from "../utils/network";
import ImageView from "../components/ImageView";

function User() {
  const columns = [
    {
      title: "Photo",
      dataIndex: "profilePicture",
      key: "profilePicture",
      width: 60,
      render: (profilePicture) =>
        profilePicture ? (
          <ImageView
            src={profilePicture}
            style={{ borderRadius: "50%", height: 45, width: 45 }}
          />
        ) : (
          <Avatar icon={<AiOutlineUser />} size={45} />
        ),
    },
    {
      title: "Wallet Address",
      dataIndex: "walletId",
      key: "walletId",
      render: (text) => (
        <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
      ),
    },
    { title: "Nick Name", dataIndex: "nickName", key: "nickName" },
    { title: "Email", dataIndex: "email", key: "email" },
    // {
    //   title: "Access Code",
    //   dataIndex: "accessCode",
    //   key: "accessCode",
    //   render: (text) => (
    //     <div
    //       style={{
    //         overflowWrap: "anywhere",
    //         textAlign: "center",
    //       }}
    //     >
    //       {text?.length > 0 ? text : "~"}
    //     </div>
    //   ),
    // },
    {
      title: "Restrict Login",
      // dataIndex: "loginRestricted",
      key: "loginRestricted",
      render: (text) => (
        <div style={{ textAlign: "center" }}>
          <Switch
            disabled={text.loginRestricted}
            defaultChecked={text?.loginRestricted}
            onChange={() => handleRestrictLogin(text)}
          />
        </div>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (fn, u) => `${fn || "~"} ${u.lastName || ""}`,
    },
    {
      title: "Type",
      dataIndex: "userType",
      key: "userType",
      render: (type) => (
        <b style={{ textTransform: "capitalize" }}>{type || "fan"}</b>
      ),
    },
  ];

  const { getList, list, loading, paramObj } = useGetListApi({
    url: USER_SERVER + "getAll",
  });

  const handleRestrictLogin = (text) => {
    API_CALL.put(USER_SERVER + "restrictLogin", { email: text?.email })
      .then((res) => {
        if (res.status === 200) message.success("Login restricted!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card bodyStyle={{ padding: 6 }} bordered className="content-card">
      <TableView
        type={"User"}
        columns={columns}
        list={list}
        loading={loading}
        paramObj={paramObj}
        getList={getList}
        searchFor="wallet Id, name, email"
        noAction
      />
    </Card>
  );
}

export default User;
