import React, { useEffect, useState } from "react";
import { Avatar, message, Select, Form, Input, Modal } from "antd";
import { BsImages } from "react-icons/bs";

import { API_CALL, SNFT_SERVER, useGetListApi } from "../utils/network";
import TabsContainer from "../components/TabsContainer";
import TableView from "../components/TableView";
import DrawerView from "../components/DrawerView";
import ImageView from "../components/ImageView";

const columns = [
  {
    title: "Image",
    dataIndex: "snftImagePath",
    key: "snftImagePath",
    width: 80,
    align: "center",
    render: (img) =>
      img ? (
        <ImageView src={img} />
      ) : (
        <Avatar alt="" shape="square" icon={<BsImages />} />
      ),
  },
  {
    title: "Creator",
    dataIndex: "creatorWalletId",
    key: "creatorWalletId",
    render: (text) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
    ),
  },
  { title: "Title", dataIndex: "snftTitle", key: "snftTitle" },
  {
    title: "Category",
    dataIndex: "snftCategory",
    key: "snftCategory",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
];

const status = {
  approved: "Approve",
  rejected: "Reject",
  featured: "Feature",
  unfeatured: "Unfeature",
  restricted: "Restrict",
  unrestricted: "Unrestrict",
};

export default function Inft() {
  const [refetch, setRefetch] = useState("");
  const [selected, setSelected] = useState({});
  const [rejectionCategory, setRejectionCategory] = useState("");
  const [rejectReason, setRejectReason] = useState("");

  const dataHandler = (type = "") => {
    setSelected({ ...selected, loading: true });
    if (type === "status") {
      let payload = { snftId: selected._id };
      if (selected.mintingStatus === "rejected") {
        payload = {
          ...payload,
          status: selected.mintingStatus,
          rejectionCategory: rejectionCategory,
          rejectReason: rejectReason,
        };
      } else if (selected.mintingStatus?.includes("featured")) {
        payload = {
          ...payload,
          action: "featured",
          featureInMarketPlace: selected.mintingStatus === "featured",
        };
      } else if (selected.mintingStatus?.includes("restricted"))
        payload = {
          ...payload,
          action: "restricted",
          restricted: selected.mintingStatus === "restricted",
        };
      else payload.status = selected.mintingStatus;

      const apiEnd = payload.status
        ? "updateTokenStatus"
        : "updateFeaturedINFTStatus";
      API_CALL({
        method: payload.status ? "post" : "put",
        url: SNFT_SERVER + apiEnd,
        data: payload,
      })
        .then((res) => {
          if (res.status === 200) {
            if (selected.alreadyRejected) setRefetch("inreview");
            else setRefetch(payload.action || selected.mintingStatus);
            message.success(
              "Brand Club " + selected.mintingStatus + " successfully"
            );
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    }
  };

  return (
    <>
      {selected._id && !selected.view && (
        <Modal
          okText={"Yes, " + status[selected.mintingStatus]}
          onOk={() => dataHandler("status")}
          onCancel={() => setSelected({})}
          title={<h3>Update Brand Club Status</h3>}
          open
          centered
          maskClosable={false}
          confirmLoading={selected.loading}
          cancelButtonProps={{ type: "danger" }}
          maskStyle={{ opacity: 1 }}
          okButtonProps={{
            disabled: selected.mintingStatus === "rejected" && !rejectReason,
          }}
        >
          <h3 style={{ color: "#333" }}>
            Are you sure to{" "}
            <b style={{ textTransform: "lowercase" }}>
              {status[selected.mintingStatus]}
            </b>{" "}
            the selected Brand Club ?
          </h3>
          {selected.mintingStatus === "rejected" && (
            <>
              <Form.Item
                labelCol={{ span: 24, style: { padding: 0 } }}
                label={
                  <span style={{ width: "100%", fontSize: 12 }}>
                    Rejection Category <span style={{ color: "red" }}>*</span>
                  </span>
                }
                wrapperCol={{ span: 24 }}
              >
                <Select
                  onChange={(value) => setRejectionCategory(value)}
                  options={[
                    { value: "Does not meet design guidelines" },
                    { value: "Inappropriate/offensive content" },
                    { value: "Poor image resolution" },
                    { value: "Denomination does not match image" },
                    { value: "Trademark content issue" },
                  ]}
                  placeholder="Select rejection category"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24, style: { padding: 0 } }}
                label={
                  <span style={{ width: "100%", fontSize: 12 }}>
                    Reason for rejection <span style={{ color: "red" }}>*</span>
                  </span>
                }
                wrapperCol={{ span: 24 }}
              >
                <Input.TextArea
                  onChange={(e) => setRejectReason(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Enter rejection reason here"
                />
              </Form.Item>
            </>
          )}
        </Modal>
      )}
      {selected._id && selected.view && (
        <DrawerView
          data={[
            { label: "Image", value: selected.snftImagePath, image: true },
            {
              label: "Marketplace Thumbnail",
              value: selected.thumbnailImagePath,
              image: true,
            },
            { label: "Creator", value: selected.creatorWalletId },
            { label: "Title", value: selected.snftTitle },
            {
              label: "Status",
              value: selected.restricted
                ? "Restricted from Listing"
                : // : selected.inftClass?.some?.(({ type }) => type === "featured")
                // ? "Featured in Market Place"
                selected.mintingStatus === "inreview"
                ? "In Review"
                : selected.mintingStatus,
            },
            ...(selected.mintingStatus === "rejected"
              ? [
                  {
                    label: "Rejection Category",
                    value: selected.rejectionCategory,
                  },
                  { label: "Rejection Reason", value: selected.rejectReason },
                ]
              : []),
            { label: "Category", value: selected.snftCategory },
          ]}
          onClose={() => setSelected({})}
          title="Brand Club"
        />
      )}
      <TabsContainer
        tabs={[
          {
            label: "In Review",
            content: (
              <SNftView
                type="In Review"
                refetch={refetch === "rejected" || refetch === "approved"}
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "mintingStatus",
                    dataIndex: "mintingStatus",
                    align: "center",
                    render: (mintingStatus, sNft) => (
                      <Select
                        placeholder="Update Status"
                        value={mintingStatus}
                        onChange={(v) =>
                          setSelected({ ...sNft, mintingStatus: v })
                        }
                        style={{ width: 120, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="inreview"
                        >
                          In Review
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                        <Select.Option value="rejected">Reject</Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Rejected",
            content: (
              <SNftView
                type="Rejected"
                refetch={refetch === "rejected" || refetch === "inreview"}
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "mintingStatus",
                    dataIndex: "mintingStatus",
                    align: "center",
                    render: (mintingStatus, sNft) => (
                      <Select
                        placeholder="Update Status"
                        value={mintingStatus}
                        onChange={(v) =>
                          setSelected({
                            ...sNft,
                            mintingStatus: v,
                            alreadyRejected: true,
                          })
                        }
                        style={{ width: 120, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="rejected"
                        >
                          Rejected
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Approved",
            content: (
              <SNftView
                type="Approved"
                refetch={
                  refetch === "inreview" ||
                  refetch === "approved" ||
                  refetch === "featured" ||
                  refetch === "restricted"
                }
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "mintingStatus",
                    dataIndex: "mintingStatus",
                    align: "center",
                    render: (mintingStatus, sNft) => (
                      <Select
                        placeholder="Update Status"
                        value={mintingStatus}
                        onChange={(v) =>
                          setSelected({ ...sNft, mintingStatus: v })
                        }
                        style={{ width: 180, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="approved"
                        >
                          Approved
                        </Select.Option>
                        {/* <Select.Option value="featured">
                          Feature To Market Place
                        </Select.Option> */}
                        <Select.Option value="restricted">
                          Restrict Listing
                        </Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          // {
          //   label: "Featured",
          //   content: (
          //     <SNftView
          //       type="Featured"
          //       refetch={refetch === "featured" || refetch === "restricted"}
          //       setRefetch={setRefetch}
          //       setSelected={setSelected}
          //       columns={[
          //         ...columns,
          //         {
          //           title: "Status",
          //           key: "featureInMarketPlace",
          //           dataIndex: "featureInMarketPlace",
          //           align: "center",
          //           render: (_, sNft) => (
          //             <Select
          //               placeholder="Update Status"
          //               value="featured"
          //               onChange={(v) =>
          //                 setSelected({ ...sNft, mintingStatus: v })
          //               }
          //               style={{ width: 180, textAlign: "left" }}
          //             >
          //               <Select.Option
          //                 style={{ display: "none" }}
          //                 value="featured"
          //               >
          //                 Featured in Market Place
          //               </Select.Option>
          //               <Select.Option value="unfeatured">
          //                 Convert to unfeatured sNFT
          //               </Select.Option>
          //               <Select.Option value="restricted">
          //                 Restrict Listing
          //               </Select.Option>
          //             </Select>
          //           ),
          //         },
          //       ]}
          //     />
          //   ),
          // },
          {
            label: "Restricted",
            content: (
              <SNftView
                type="Restricted"
                refetch={refetch === "restricted"}
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "restricted",
                    dataIndex: "restricted",
                    align: "center",
                    render: (_, sNft) => (
                      <Select
                        placeholder="Update Status"
                        value="restricted"
                        onChange={(v) =>
                          setSelected({ ...sNft, mintingStatus: v })
                        }
                        style={{ width: 180, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="restricted"
                        >
                          Restricted from Listing
                        </Select.Option>
                        <Select.Option value="unrestricted">
                          Unestrict from Listing
                        </Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "All",
            content: (
              <SNftView
                type="All"
                refetch={refetch === "all"}
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    dataIndex: "mintingStatus",
                    key: "mintingStatus",
                    render: (mintingStatus, sNFT) => (
                      <span style={{ textTransform: "capitalize" }}>
                        {sNFT.restricted
                          ? "Restricted from Listing"
                          : // : sNFT.featureInMarketPlace
                          // ? "Featured in Market Place"
                          mintingStatus === "inreview"
                          ? "In Review"
                          : mintingStatus}
                      </span>
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </>
  );
}

const SNftView = ({
  type = "",
  columns = [],
  setSelected = () => {},
  refetch = false,
  setRefetch = () => {},
}) => {
  const { getList, list, loading, paramObj } = useGetListApi({
    url: SNFT_SERVER + "getAll/" + type.replace(" ", "").toLowerCase(),
  });

  useEffect(() => {
    if (refetch) {
      if (list.length > 1 || paramObj.page === 1) getList();
      else getList({ ...paramObj, page: paramObj.page - 1 });
      getList();
      setRefetch("");
    }
  }, [refetch]);

  return (
    <TableView
      type={type + " Brand Club"}
      columns={columns}
      list={list}
      loading={loading}
      paramObj={paramObj}
      setSelected={setSelected}
      getList={getList}
    />
  );
};
