import AccessNft from "../screens/AccessNft";
import Agent from "../screens/Agent";
import Banner from "../screens/Banner";
import Bundle from "../screens/Bundle";
import Collection from "../screens/Collection";
import Course from "../screens/Course";
import CreatorHubAccess from "../screens/CreatorHubAccess";
import FanScore from "../screens/FanScore";
import Inft from "../screens/Inft";
import NftBucks from "../screens/NftBucks";
import Notification from "../screens/Notification";
import SubAdmin from "../screens/SubAdmin";
import User from "../screens/User";
import UserAccess from "../screens/UserAccess";

export const routes = [
  { label: "Banner", path: "/banner", component: Banner },
  { label: "Course", path: "/course", component: Course },
  { label: "User", path: "/user", component: User },
  { label: "Sandbox Access", path: "/sandbox-access", component: UserAccess },
  { label: "Fan Score", path: "/fanscore", component: FanScore },
  { label: "Brand Club", path: "/brand-club", component: Inft },
  { label: "Collection", path: "/collection", component: Collection },
  { label: "NFT Bucks", path: "/nft-bucks", component: NftBucks },
  { label: "Bundle", path: "/bundle", component: Bundle },
  { label: "Access NFT", path: "/AccessNFT", component: AccessNft },
  { label: "Notification", path: "/notification", component: Notification },
  { label: "Agent", path: "/agent", component: Agent },
  { label: "Sub Admin", path: "/sub-admin", component: SubAdmin },
  {
    label: "CreatorHub Access Request",
    path: "/creatorhub-access",
    component: CreatorHubAccess,
  },
];
