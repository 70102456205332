import React, { useEffect, useState } from "react";
import { Avatar, Form, Input, message, Modal, Select } from "antd";
import { BsImages } from "react-icons/bs";

import { API_CALL, ACCESS_NFT_SERVER, useGetListApi } from "../utils/network";
import TableView from "../components/TableView";
import TabsContainer from "../components/TabsContainer";
import ImageView from "../components/ImageView";
import DrawerView from "../components/DrawerView";

const columns = [
  {
    title: "Image",
    dataIndex: "accessNFTImagePath",
    key: "accessNFTImagePath",
    width: 80,
    align: "center",
    render: (accessNFTImagePath = "") =>
      accessNFTImagePath ? (
        <ImageView src={accessNFTImagePath} />
      ) : (
        <Avatar alt="" shape="square" icon={<BsImages />} />
      ),
  },
  {
    title: "Creator",
    dataIndex: "creatorWalletId",
    key: "creatorWalletId",
    render: (walletId = "") => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>
        {walletId}
      </div>
    ),
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (title = "") => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{title}</div>
    ),
  },
  {
    title: "Access",
    dataIndex: "accessNftType",
    key: "accessNftType",
    render: (accessNftType = "") => (
      <span style={{ textTransform: "capitalize" }}>{accessNftType}</span>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type = "") => (
      <span style={{ textTransform: "capitalize" }}>
        {type.replace("access", " Access")}
      </span>
    ),
  },
  {
    title: "Rarity",
    dataIndex: "rarity",
    key: "rarity",
    render: (rarity = "") => (
      <span style={{ textTransform: "capitalize" }}>{rarity}</span>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (price = 0) => (price ? "$" + price : "Not Set"),
  },
];
const rareOptions = [
  "Common",
  "Collectable",
  "Rare",
  "Ultra-Rare",
  "Legendary",
];
const accessTypeOptions = [
  "Digitalaccess",
  "Eventaccess",
  "Productaccess",
  "Certificateaccess",
];
const statusObj = {
  approved: "Approve",
  rejected: "Reject",
  featured: "Feature",
  unfeatured: "Unfeature",
  restricted: "Restrict",
  unrestricted: "Unrestrict",
};
const typeObj = {
  inreview: "Pending Approval",
  approved: "Approved",
  rejected: "Rejected",
  restricted: "Restricted",
  created: "In Progress",
};

export default function AccessNft() {
  const [refetch, setRefetch] = useState("");
  const [selected, setSelected] = useState({});
  const [rejectionCategory, setRejectionCategory] = useState("");
  const [rejectReason, setRejectReason] = useState("");

  const dataHandler = (type = "") => {
    setSelected({ ...selected, loading: true });
    if (type === "status") {
      let apiEnd = "approveAccessNFT";
      let payload = { accessNftId: selected._id };
      if (selected.status === "rejected") {
        payload = {
          ...payload,
          rejectionCategory: rejectionCategory,
          rejectReason: rejectReason,
        };
      } else if (selected.status.includes("feature")) {
        apiEnd = "featureInMarketPlace";
        payload = {
          ...payload,
          action: "featureInMarketPlace",
          featureInMarketPlace: selected.featureInMarketPlace ? false : true,
        };
      } else if (selected.status.includes("restrict")) {
        apiEnd = "restrict";
        payload = {
          ...payload,
          action: "restrict",
          restricted: selected.restricted ? false : true,
        };
      } else payload.status = selected.status;

      API_CALL.put(ACCESS_NFT_SERVER + apiEnd, payload)
        .then((res) => {
          if (res.status === 200) {
            setRefetch(
              selected.alreadyRejected ? "unrejected" : selected.status
            );
            message.success("Access NFT " + selected.status + " successfully");
          }
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    }
  };

  return (
    <>
      {selected?._id && !selected.view && (
        <Modal
          okText={"Yes, " + statusObj[selected.status]}
          onOk={() => dataHandler("status")}
          onCancel={() => setSelected({})}
          title={<h3>{statusObj[selected.status]} Access NFT</h3>}
          open
          centered
          maskClosable={false}
          confirmLoading={selected.loading}
          cancelButtonProps={{ type: "danger" }}
          maskStyle={{ opacity: 1 }}
        >
          <h3 style={{ color: "#333" }}>
            Are you sure to{" "}
            <strong style={{ textTransform: "lowercase" }}>
              {statusObj[selected.status]}
            </strong>{" "}
            the selected Access NFT ?
          </h3>
          {selected.mintingStatus === "rejected" && (
            <>
              <Form.Item
                labelCol={{ span: 24, style: { padding: 0 } }}
                label={
                  <span style={{ width: "100%", fontSize: 12 }}>
                    Rejection Category <span style={{ color: "red" }}>*</span>
                  </span>
                }
                wrapperCol={{ span: 24 }}
              >
                <Select
                  onChange={(value) => setRejectionCategory(value)}
                  options={[
                    { value: "Does not meet design guidelines" },
                    { value: "Inappropriate/offensive content" },
                    { value: "Poor image resolution" },
                    { value: "Denomination does not match image" },
                    { value: "Trademark content issue" },
                  ]}
                  placeholder="Select rejection category"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24, style: { padding: 0 } }}
                label={
                  <span style={{ width: "100%", fontSize: 12 }}>
                    Reason for rejection <span style={{ color: "red" }}>*</span>
                  </span>
                }
                wrapperCol={{ span: 24 }}
              >
                <Input.TextArea
                  onChange={(e) => setRejectReason(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Enter rejection reason here"
                />
              </Form.Item>
            </>
          )}
        </Modal>
      )}
      {selected._id && selected.view && (
        <DrawerView
          data={[
            {
              label: "Image",
              value: selected.accessNFTImagePath,
              image: true,
              fullWidth: true,
            },
            { label: "Creator", value: selected.creatorWalletId },
            { label: "Title", value: selected.title },
            { label: "Access", value: selected.accessNftType },
            {
              label: "Price",
              value: selected.price ? "$" + selected.price : "Not Set",
            },
            {
              label: "Status",
              value:
                typeObj[selected.approvalStatus] || selected.approvalStatus,
            },
            ...(selected.approvalStatus === "rejected"
              ? [
                  {
                    label: "Rejection Category",
                    value: selected.rejectionCategory,
                  },
                  { label: "Rejection Reason", value: selected.rejectReason },
                ]
              : []),
            {
              label: "Delivery Type",
              value: (selected.deliveryOption || "NA").replace("delivery", ""),
            },
            { label: "Rarity", value: selected.rarity },
            {
              label: "Type",
              value: (selected.type || "").replace("access", " Access"),
            },
            {
              label: "Supply",
              value:
                (selected.supplyType || "NA") +
                (selected.supplyType === "limited"
                  ? ` (${selected.quantity || 0})`
                  : ""),
            },
            {
              label: "Collection Detail",
              value: selected.accessNftCollectionId || "NA",
            },
            { label: "Active", value: selected.isActive ? "Yes" : "No" },
          ]}
          onClose={() => setSelected({})}
          title="Access NFT"
        />
      )}
      <TabsContainer
        tabs={[
          {
            label: "Pending Approval",
            content: (
              <AccessNftView
                type="inreview"
                refetch={refetch === "approved" || refetch === "rejected"}
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "approvalStatus",
                    dataIndex: "approvalStatus",
                    align: "center",
                    render: (_, accessNft = {}) => (
                      <Select
                        placeholder="Update Status"
                        value="inreview"
                        onChange={(status = "") =>
                          setSelected({ ...accessNft, status: status })
                        }
                        style={{ width: 220, textAlign: "left" }}
                      >
                        <Select.Option
                          value="inreview"
                          style={{ display: "none" }}
                        >
                          Pending Approval
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                        <Select.Option value="rejected">Reject</Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Rejected",
            content: (
              <AccessNftView
                type="rejected"
                refetch={refetch === "unrejected"} // approved after rejected
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "approvalStatus",
                    dataIndex: "approvalStatus",
                    align: "center",
                    render: (_, accessNft = {}) => (
                      <Select
                        placeholder="Update Status"
                        value="rejected"
                        onChange={(status = "") =>
                          setSelected({
                            ...accessNft,
                            status: status,
                            alreadyRejected: true,
                          })
                        }
                        style={{ width: 220, textAlign: "left" }}
                      >
                        <Select.Option
                          value="rejected"
                          style={{ display: "none" }}
                        >
                          Rejected
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Approved",
            content: (
              <AccessNftView
                type="approved"
                refetch={refetch !== ""}
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "approvalStatus",
                    dataIndex: "approvalStatus",
                    align: "center",
                    render: (_, accessNft = {}) => (
                      <Select
                        placeholder="Update Status"
                        value={
                          accessNft.restricted
                            ? "restricted"
                            : accessNft.featureInMarketPlace
                            ? "featured"
                            : "approved"
                        }
                        onChange={(status) =>
                          setSelected({ ...accessNft, status: status })
                        }
                        style={{ width: 220, textAlign: "left" }}
                      >
                        {accessNft.restricted ? (
                          <Select.Option
                            value="restricted"
                            style={{ display: "none" }}
                          >
                            Restricted from Listing
                          </Select.Option>
                        ) : accessNft.featureInMarketPlace ? (
                          <Select.Option
                            value="featured"
                            style={{ display: "none" }}
                          >
                            Featured in Market Place
                          </Select.Option>
                        ) : (
                          <Select.Option
                            value="approved"
                            style={{ display: "none" }}
                          >
                            Approved
                          </Select.Option>
                        )}
                        {accessNft.featureInMarketPlace ? (
                          <Select.Option value="unfeatured">
                            Unfeature from Market Place
                          </Select.Option>
                        ) : (
                          <Select.Option value="featured">
                            Feature in Market Place
                          </Select.Option>
                        )}
                        {accessNft.restricted ? (
                          <Select.Option value="unrestricted">
                            Unrestrict from Listing
                          </Select.Option>
                        ) : (
                          <Select.Option value="restricted">
                            Restrict Listing
                          </Select.Option>
                        )}
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Restricted",
            content: (
              <AccessNftView
                type="restricted"
                refetch={refetch === "unrestricted"}
                setRefetch={setRefetch}
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "approvalStatus",
                    dataIndex: "approvalStatus",
                    align: "center",
                    render: (_, accessNft = {}) => (
                      <Select
                        placeholder="Update Status"
                        value="restricted"
                        onChange={(status) =>
                          setSelected({ ...accessNft, status: status })
                        }
                        style={{ width: 220, textAlign: "left" }}
                      >
                        <Select.Option
                          value="restricted"
                          style={{ display: "none" }}
                        >
                          Restricted from Listing
                        </Select.Option>
                        <Select.Option value="unrestricted">
                          Unrestrict from Listing
                        </Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "In Progress",
            content: (
              <AccessNftView
                type="created"
                setSelected={setSelected}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "approvalStatus",
                    dataIndex: "approvalStatus",
                    render: () => "In Progress",
                    align: "center",
                  },
                ]}
              />
            ),
          },
          // {
          //   label: "Featured",
          //   content: (
          //     <AccessNftView
          //       type="Featured"
          //       refetch={refetch !== ""}
          //       defaultParams={{
          //         ...defaultParamObj,
          //         featureInMarketPlace: true,
          //       }}
          //       setRefetch={setRefetch}
          //       setSelected={setSelected}
          //     />
          //   ),
          // },
        ]}
      />
    </>
  );
}

const AccessNftView = ({
  type = "",
  columns = [],
  setSelected = () => {},
  refetch = false,
  setRefetch = () => {},
}) => {
  const { getList, list, loading, paramObj } = useGetListApi({
    url: ACCESS_NFT_SERVER + "getAll/" + type.toLowerCase(),
  });

  useEffect(() => {
    if (refetch) {
      if (list.length > 1 || paramObj.page === 1) getList();
      else getList({ ...paramObj, page: paramObj.page - 1 });
      setRefetch("");
    }
  }, [refetch]);

  return (
    <TableView
      type={typeObj[type] + " Access NFT"}
      loading={loading}
      paramObj={paramObj}
      list={list}
      getList={({ searchKeyword = "", ...paramObj }) =>
        getList({
          ...paramObj,
          ...(searchKeyword ? { title: searchKeyword } : {}),
        })
      }
      setSelected={setSelected}
      columns={columns}
      searchFor="Title"
      otherFilters={
        <>
          <Select
            placeholder="Select Rarity"
            style={{ width: "20%", marginBottom: 12, minWidth: 220 }}
            onChange={(v) => getList({ ...paramObj, rarity: v, page: 1 })}
            allowClear
          >
            {rareOptions.map((rarity = "") => (
              <Select.Option
                key={rarity}
                value={rarity.replace("-", "").toLowerCase()}
              >
                {rarity}
              </Select.Option>
            ))}
          </Select>
          <Select
            placeholder="Select Type"
            style={{ width: "20%", marginBottom: 12, minWidth: 260 }}
            onChange={(v) => getList({ ...paramObj, type: v, page: 1 })}
            allowClear
          >
            {accessTypeOptions.map((type = "") => (
              <Select.Option key={type} value={type.toLowerCase()}>
                {type.replace("access", " Access")}
              </Select.Option>
            ))}
          </Select>
        </>
      }
    />
  );
};
