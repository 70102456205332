import React, { useEffect, useState } from "react";
import { Avatar, message, Select, Form, Input, Modal, Carousel } from "antd";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BsImages } from "react-icons/bs";
import html2canvas from "html2canvas";

import {
  API_CALL,
  IMAGE_BASE_URL,
  BUCKS_SERVER,
  useGetListApi,
  FORM_DATA_API_CALL,
  USER_SERVER,
  IMAGE_PUBLIC_BASE_URL,
} from "../utils/network";
import TabsContainer from "../components/TabsContainer";
import TableView from "../components/TableView";
import DrawerView from "../components/DrawerView";
import ImageView from "../components/ImageView";

const columns = [
  {
    title: "Image",
    dataIndex: "imagePath",
    key: "imagePath",
    width: 80,
    align: "center",
    render: (imagePath) =>
      imagePath ? (
        <ImageView src={IMAGE_BASE_URL + imagePath} />
      ) : (
        <Avatar alt="" shape="square" icon={<BsImages />} />
      ),
  },
  {
    title: "Creator",
    dataIndex: "walletId",
    key: "walletId",
    render: (text) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{text}</div>
    ),
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (t) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{t}</div>
    ),
  },
  {
    title: "Rarity Class",
    dataIndex: "rarityClass",
    key: "rarityClass",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
  {
    title: "Media Type",
    dataIndex: "videoUrl",
    key: "videoUrl",
    render: (videoUrl) => (videoUrl ? "Video" : "Image"),
  },
  {
    title: "Collection",
    dataIndex: "collectionDetail",
    key: "collectionDetail",
    render: (t) => (
      <div style={{ overflowWrap: "anywhere", minWidth: "8vw" }}>{t}</div>
    ),
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (t) => <span style={{ textTransform: "capitalize" }}>{t}</span>,
  },
  {
    title: "Market Price",
    dataIndex: "marketPrice",
    key: "marketPrice",
    render: (price, { isAdvertisement }) =>
      isAdvertisement ? "Unavailable" : "$" + price,
  },
  // { title: "Denomination", dataIndex: "denomination", key: "denomination" },
  // { title: "Supply", dataIndex: "supply", key: "supply" },
];

const status = {
  approved: "Approve",
  rejected: "Reject",
  featured: "Feature",
  unfeatured: "Unfeature",
  restricted: "Restrict",
  unrestricted: "Unrestrict",
};

const denominations = [
  1, 5, 10, 20, 50, 100, 500, 1000, 10000, 100000, 1000000,
];

export default function NftBucks() {
  const [refetchBucks, setRefetchBucks] = useState("");
  const [selected, setSelected] = useState({});
  const [rejectionCategory, setRejectionCategory] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const adbuckRefs = React.useRef([]);

  React.useEffect(() => {
    if (selected.isAdvertisement) {
      setImgLoading(true);
      const loadImage = (imageUrl = "") => {
        return new Promise((resolve, reject) => {
          const loadImg = new window.Image();
          loadImg.src = imageUrl;
          loadImg.onload = () => resolve(imageUrl);
          loadImg.onerror = (err) => reject(err);
        });
      };

      Promise.all([
        loadImage(IMAGE_BASE_URL + selected.imagePath),
        ...denominations.map((denomination) =>
          loadImage(
            `${IMAGE_PUBLIC_BASE_URL}/overlays/Adbuck/${denomination}.webp`
          )
        ),
      ])
        .then(() => setImgLoading(false))
        .catch((err) => setImgLoading(false));
    }
  }, [selected.isAdvertisement]);

  const dataHandler = async (type = "") => {
    setSelected({ ...selected, loading: true });
    if (type === "status") {
      const buckId = selected._id;
      let apiEnd = "updateTokenStatus";
      let payload = { status: selected.bucksStatus };
      if (selected.bucksStatus?.includes("featured")) {
        apiEnd = "updateFeaturedBuckStatus";
        payload = { featureInMarketPlace: selected.bucksStatus === "featured" };
      } else if (selected.bucksStatus?.includes("restricted")) {
        apiEnd = "restrictMarketplaceListing";
        payload = { restricted: selected.bucksStatus === "restricted" };
      } else if (selected.bucksStatus === "rejected") {
        payload = { ...payload, rejectReason, rejectionCategory };
      } else if (
        selected.isAdvertisement &&
        (!selected.videoUrl || selected.bbDataSubmissionStatus === "completed")
      ) {
        payload.imagePath = await adbuckUploadHandler();
      }

      API_CALL({
        method: apiEnd === "updateTokenStatus" ? "POST" : "PUT",
        url: BUCKS_SERVER + apiEnd,
        data: { ...payload, buckId },
      })
        .then((res) => {
          if (res.status === 200) {
            setRefetchBucks(selected.type);
            message.success("Buck " + selected.bucksStatus + " successfully !");
          } else if (selected.videoUrl) setRefetchBucks(selected.type);
          setSelected({});
        })
        .catch((err) => {
          console.log(err);
          setSelected({});
        });
    }
  };

  const adbuckUploadHandler = async () => {
    const category = "adbuck_" + selected._id;
    const imagePathArray = await Promise.all(
      denominations.map(
        (denomination = 1, index = 0) =>
          new Promise((resolve, reject) => {
            html2canvas(adbuckRefs.current[index], {
              useCORS: true,
              backgroundColor: "rgba(0,0,0,0)",
              logging: false,
            })
              .then(async function (canvas) {
                try {
                  const image = canvas.toDataURL("image/png");
                  const blob = await (await fetch(image)).blob();
                  const fileName = `Denomination-${denomination}.png`;
                  const file = new File([blob], fileName, { type: blob.type });
                  const formData = new FormData();
                  formData.append("category", category);
                  formData.append("file", file);
                  const res = await FORM_DATA_API_CALL.post(
                    USER_SERVER + "uploadFile",
                    formData,
                    { params: { walletId: selected.walletId } }
                  );
                  if (res.status === 200 && res?.data?.imgaePath) {
                    const path = res.data.imgaePath.split("amazonaws.com/")[1];
                    if (!path) resolve({});
                    else resolve({ denomination, imagePath: path });
                  } else resolve({});
                } catch (error) {
                  console.log(error);
                  reject({});
                }
              })
              .catch((err) => {
                console.log(err);
                reject({});
              });
          })
      )
    );
    return imagePathArray.reduce((imagePath = {}, data = {}) => {
      return { ...imagePath, [data.denomination]: data.imagePath };
    }, {});
  };

  return (
    <>
      {selected?._id && (!selected.view || selected.adbuckView) && (
        <Modal
          okText={"Yes, " + status[selected.bucksStatus]}
          onOk={() => dataHandler("status")}
          onCancel={() => setSelected({})}
          title={
            <h3>
              Update {selected.isAdvertisement ? "AdBuck" : "NFT Buck"} Status
            </h3>
          }
          zIndex={9999}
          open
          centered
          maskClosable={false}
          confirmLoading={selected.loading}
          cancelButtonProps={{ type: "danger" }}
          maskStyle={{ opacity: 1 }}
          okButtonProps={{
            disabled:
              selected.bucksStatus === "rejected" && !rejectionCategory
                ? true
                : false,
          }}
        >
          <h3 style={{ color: "#333" }}>
            Are you sure to{" "}
            <b style={{ textTransform: "lowercase" }}>
              {status[selected.bucksStatus]}
            </b>{" "}
            the selected {selected.isAdvertisement ? "Ad" : "NFT"}Buck ?
          </h3>
          {selected.bucksStatus === "rejected" && (
            <>
              <Form.Item
                labelCol={{ span: 24, style: { padding: 0 } }}
                label={
                  <span style={{ width: "100%", fontSize: 12 }}>
                    Rejection Category <span style={{ color: "red" }}>*</span>
                  </span>
                }
                wrapperCol={{ span: 24 }}
              >
                <Select
                  onChange={(value) => setRejectionCategory(value)}
                  options={[
                    { value: "Does not meet design guidelines" },
                    { value: "Inappropriate/offensive content" },
                    { value: "Poor image resolution" },
                    { value: "Denomination does not match image" },
                    { value: "Trademark content issue" },
                  ]}
                  placeholder="Select rejection category"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24, style: { padding: 0 } }}
                label={
                  <span style={{ width: "100%", fontSize: 12 }}>
                    Reason for rejection
                  </span>
                }
                wrapperCol={{ span: 24 }}
              >
                <Input.TextArea
                  onChange={(e) => setRejectReason(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Enter rejection reason here"
                />
              </Form.Item>
            </>
          )}
        </Modal>
      )}
      {selected._id && selected.view && (
        <DrawerView
          data={[
            ...(selected.isAdvertisement &&
            (selected.bucksStatus !== "approved" || selected.adbuckView)
              ? []
              : [
                  {
                    label: "Image",
                    value: IMAGE_BASE_URL + selected.imagePath,
                    image: true,
                    fullWidth: true,
                  },
                ]),
            ...(selected.videoUrl
              ? [
                  {
                    label: "Video",
                    fullWidth: true,
                    custom: (
                      <div style={{ height: 200, width: 200 }}>
                        <video
                          src={selected.videoUrl}
                          style={{ objectFit: "contain" }}
                          controls
                          height="100%"
                          width="100%"
                        />
                      </div>
                    ),
                  },
                ]
              : []),
            { label: "Title", value: selected.title },
            { label: "Creator", value: selected.walletId },
            {
              label: "Status",
              value: selected.restricted
                ? "Restricted from Listing"
                : // : selected.featureInMarketPlace
                // ? "Featured in Market Place"
                selected.bucksStatus === "created"
                ? selected.updateCount
                  ? "Resubmitted"
                  : "Pending Approval"
                : selected.bucksStatus,
            },
            {
              label: "Available in Marketplace",
              value: selected.featureInMarketPlace ? "Yes" : "No",
            },
            ...(selected.bucksStatus === "rejected"
              ? [
                  {
                    label: "Rejection Category",
                    value: selected.rejectionCategory,
                  },
                ]
              : []),
            { label: "Rarity Class", value: selected.rarityClass },
            { label: "Collection Detail", value: selected.collectionDetail },
            { label: "Category", value: selected.category },
            {
              label: "Market Price",
              value: selected.isAdvertisement
                ? "Unavailable"
                : "$" + selected.marketPrice,
            },
            {
              label: "Denomination",
              value: selected.isAdvertisement
                ? "Dynamic"
                : "$" + selected.denomination,
            },
            { label: "Supply", value: selected.adsupply || selected.supply },
            {
              label: "Description",
              value: selected.description,
              multiline: true,
              custom: selected.isAdvertisement ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: selected.description || "",
                  }}
                  className="inner_html_content"
                />
              ) : null,
            },
          ]}
          onClose={() => setSelected({})}
          title="NFTbucks"
          extra={
            selected.isAdvertisement &&
            (selected.bucksStatus !== "approved" || selected.adbuckView) && (
              <Select
                placeholder="Update Status"
                value={selected.updateCount ? "resubmitted" : "created"}
                onChange={(updatedStatus) =>
                  imgLoading
                    ? message.warning("Loading, Please wait")
                    : selected.bbDataSubmissionStatus === "pending"
                    ? message.warning(
                        "Data Submitted To Banner Bear For Video Creation, Kindly Check In Some Time Again"
                      )
                    : setSelected({
                        ...selected,
                        bucksStatus: updatedStatus,
                        type: "created",
                        adbuckView: true,
                      })
                }
                loading={imgLoading}
                style={{ width: 180, textAlign: "left" }}
              >
                <Select.Option style={{ display: "none" }} value="created">
                  Pending Approval
                </Select.Option>
                <Select.Option style={{ display: "none" }} value="resubmitted">
                  Resubmitted
                </Select.Option>
                <Select.Option value="approved">Approve</Select.Option>
                <Select.Option value="rejected">Reject</Select.Option>
              </Select>
            )
          }
        >
          {selected.isAdvertisement &&
            (selected.bucksStatus !== "approved" || selected.adbuckView) && (
              <>
                <span style={{ color: "#555", fontSize: 13 }} id="my-node-1">
                  AdBuck with Denomination Overlays :
                </span>
                <div style={{ padding: 20 }}>
                  {/* <button
                onClick={() => carousel.current && carousel.current.prev()}
              >
                Prev
              </button> */}
                  <Carousel
                    // ref={carousel}
                    arrows
                    prevArrow={<AiOutlineArrowLeft color="black" />}
                    nextArrow={<AiOutlineArrowRight color="black" />}
                    slidesToShow={2}
                    dots={false}
                    centerMode
                    centerPadding="25px"
                    draggable
                    swipe
                    swipeToSlide
                  >
                    {denominations.map((denomination = 0, index = 0) => (
                      <div
                        key={denomination}
                        className="adbuck-image-preview"
                        id={"adbuck-" + denomination}
                      >
                        <div
                          className="adbuck-image-main"
                          ref={(ref) => (adbuckRefs.current[index] = ref)}
                        >
                          <div className="adbuck-denomination-overlay">
                            <ImageView
                              src={`${IMAGE_PUBLIC_BASE_URL}/overlays/Adbuck/${denomination}.webp`}
                              preview={false}
                              // {src: IMAGE_BASE_URL + selected.imagePath,
                              // className: "adbuck-overlay-" + denomination,}
                              style={{ objectFit: "cover" }}
                            />
                          </div>
                          <ImageView
                            preview={false}
                            src={IMAGE_BASE_URL + selected.imagePath}
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                      </div>
                    ))}
                  </Carousel>
                  {/* <button
                  onClick={() => carousel.current && carousel.current.next()}
                >
                  Get Image
                </button> */}
                </div>
              </>
            )}
        </DrawerView>
      )}
      <TabsContainer
        tabs={[
          {
            label: "Pending Approval",
            content: (
              <BucksView
                type="Created"
                setSelected={setSelected}
                refetchBucks={refetchBucks === "created"}
                setRefetchBucks={setRefetchBucks}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "bucksStatus",
                    dataIndex: "bucksStatus",
                    align: "center",
                    render: (bucksStatus, buck) => (
                      <Select
                        placeholder="Update Status"
                        value={buck.updateCount ? "resubmitted" : bucksStatus}
                        onChange={(updatedStatus) =>
                          buck.isAdvertisement &&
                          updatedStatus === "approved" &&
                          (!buck.videoUrl ||
                            buck.bbDataSubmissionStatus === "completed")
                            ? setSelected({ ...buck, view: true })
                            : updatedStatus === "approved" &&
                              buck.bbDataSubmissionStatus === "pending"
                            ? message.warning(
                                "Data Submitted To Banner Bear For Video Creation, Kindly Check In Some Time Again"
                              )
                            : setSelected({
                                ...buck,
                                bucksStatus: updatedStatus,
                                type: "created",
                              })
                        }
                        style={{ width: 180, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="created"
                        >
                          Pending Approval
                        </Select.Option>
                        <Select.Option
                          style={{ display: "none" }}
                          value="resubmitted"
                        >
                          Resubmitted
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                        <Select.Option value="rejected">Reject</Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Rejected",
            content: (
              <BucksView
                type="Rejected"
                setSelected={setSelected}
                refetchBucks={
                  refetchBucks === "created" || refetchBucks === "rejected"
                }
                setRefetchBucks={setRefetchBucks}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "bucksStatus",
                    dataIndex: "bucksStatus",
                    align: "center",
                    render: (bucksStatus, buck) => (
                      <Select
                        placeholder="Update Status"
                        value={bucksStatus}
                        onChange={(updatedStatus) =>
                          setSelected({
                            ...buck,
                            bucksStatus: updatedStatus,
                            type: "rejected",
                          })
                        }
                        style={{ width: 120, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="rejected"
                        >
                          Rejected
                        </Select.Option>
                        <Select.Option value="approved">Approve</Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "Approved",
            content: (
              <BucksView
                type="Approved"
                setSelected={setSelected}
                refetchBucks={refetchBucks !== ""}
                setRefetchBucks={setRefetchBucks}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    key: "bucksStatus",
                    dataIndex: "bucksStatus",
                    align: "center",
                    render: (bucksStatus, buck) => (
                      <Select
                        placeholder="Update Status"
                        value={buck.restricted ? "restricted" : bucksStatus}
                        onChange={(updatedStatus) =>
                          setSelected({
                            ...buck,
                            bucksStatus: updatedStatus,
                            type: "approved",
                          })
                        }
                        style={{ width: 180, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="approved"
                        >
                          Approved
                        </Select.Option>
                        {/* <Select.Option value="featured">
                          Feature To Market Place
                        </Select.Option> */}
                        <Select.Option value="restricted">
                          Restrict Listing
                        </Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          // {
          //   label: "Featured",
          //   content: (
          //     <BucksView
          //       type="Featured"
          //       setSelected={setSelected}
          //       refetchBucks={
          //         refetchBucks === "approved" || refetchBucks === "featured"
          //       }
          //       setRefetchBucks={setRefetchBucks}
          //       columns={[
          //         ...columns,
          //         {
          //           title: "Status",
          //           key: "featureInMarketPlace",
          //           dataIndex: "featureInMarketPlace",
          //           align: "center",
          //           render: (_, buck) => (
          //             <Select
          //               placeholder="Update Status"
          //               value="featured"
          //               onChange={(updatedStatus) =>
          //                 setSelected({
          //                   ...buck,
          //                   bucksStatus: updatedStatus,
          //                   type: "featured",
          //                 })
          //               }
          //               style={{ width: 220, textAlign: "left" }}
          //             >
          //               <Select.Option
          //                 style={{ display: "none" }}
          //                 value="featured"
          //               >
          //                 Featured in Market Place
          //               </Select.Option>
          //               <Select.Option value="unfeatured">
          //                 Convert To Normal Bucks
          //               </Select.Option>
          //               <Select.Option value="restricted">
          //                 Restrict Listing
          //               </Select.Option>
          //             </Select>
          //           ),
          //         },
          //       ]}
          //     />
          //   ),
          // },
          {
            label: "Restricted",
            content: (
              <BucksView
                type="Restricted"
                setSelected={setSelected}
                refetchBucks={
                  refetchBucks === "approved" ||
                  refetchBucks === "featured" ||
                  refetchBucks === "restricted"
                }
                setRefetchBucks={setRefetchBucks}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    dataIndex: "restricted",
                    key: "restricted",
                    align: "center",
                    render: (_, buck) => (
                      <Select
                        placeholder="Update Status"
                        value="restricted"
                        onChange={(updatedStatus) =>
                          setSelected({
                            ...buck,
                            bucksStatus: updatedStatus,
                            type: "restricted",
                          })
                        }
                        style={{ width: 220, textAlign: "left" }}
                      >
                        <Select.Option
                          style={{ display: "none" }}
                          value="restricted"
                        >
                          Restricted from Listing
                        </Select.Option>
                        <Select.Option value="unrestricted">
                          Unrestrict from Listing
                        </Select.Option>
                      </Select>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            label: "All",
            content: (
              <BucksView
                type="All"
                setSelected={setSelected}
                refetchBucks={refetchBucks !== ""}
                setRefetchBucks={setRefetchBucks}
                columns={[
                  ...columns,
                  {
                    title: "Status",
                    dataIndex: "bucksStatus",
                    key: "bucksStatus",
                    render: (bucksStatus, buck) => (
                      <span style={{ textTransform: "capitalize" }}>
                        {buck.restricted
                          ? "Restricted from Listing"
                          : // : buck.featureInMarketPlace
                          // ? "Featured in Market Place"
                          bucksStatus === "created"
                          ? buck.updateCount
                            ? "Resubmitted"
                            : "Pending Approval"
                          : bucksStatus}
                      </span>
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </>
  );
}

const BucksView = ({
  type = "",
  columns = [],
  setSelected = () => {},
  refetchBucks = false,
  setRefetchBucks = () => {},
}) => {
  const { getList, list, loading, paramObj } = useGetListApi({
    url: BUCKS_SERVER + "getAll/" + type.toLowerCase(),
  });

  useEffect(() => {
    if (refetchBucks) {
      if (list.length > 1 || paramObj.page === 1) getList();
      else getList({ ...paramObj, page: paramObj.page - 1 });
      setRefetchBucks("");
    }
  }, [refetchBucks]);

  return (
    <TableView
      type={type === "Created" ? "Pending Approval Bucks" : type + " Bucks"}
      columns={columns}
      list={list}
      loading={loading}
      paramObj={paramObj}
      setSelected={setSelected}
      getList={getList}
      // showSearch
      filterFor="Rarity Class"
      filterOptions={[
        ...["Common", "Collectable", "Rare"],
        ...["Ultra-Rare", "Legendary", "AdBuck"],
      ].map((rarity) => ({
        label: rarity,
        value: rarity.replace("-", "").toLowerCase(),
      }))}
    />
  );
};
